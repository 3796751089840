import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import useMedia from 'use-media'
import { format } from 'date-fns'

import { canUseWindow } from '../utils'
import Layout from '../layout'
import { SEO, More } from '../components'

export const query = graphql`
  query updateQuery($slug: String!, $next: Int!, $other: Int!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        index
      }
      frontmatter {
        slug
        title
        author
        excerpt
        categories
        date
        ogimage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    nextUpdate: markdownRemark(fields: { index: { eq: $next } }) {
      excerpt
      fields {
        index
      }
      frontmatter {
        slug
        title
      }
    }
    otherUpdate: markdownRemark(fields: { index: { eq: $other } }) {
      excerpt
      fields {
        index
      }
      frontmatter {
        slug
        title
      }
    }
  }
`

const Update = ({ data }) => {
  const isWide = useMedia({ minWidth: 940 })

  const {
    slug,
    title,
    author,
    categories,
    date,
    ogimage,
    excerpt,
  } = data.markdownRemark.frontmatter

  useEffect(() => {
    if (canUseWindow) {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        contentTitle: title,
        contentAuthor: author && author[0],
        contentDate: format(new Date(date), 'yyyy-MM-dd'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { nextUpdate, otherUpdate } = data
  const moreUpdates = [nextUpdate, otherUpdate].filter(update => update)

  const disqusShortname = 'livechat-developers'
  const disqusConfig = {
    url: `${process.env.GATSBY_SITE_URL}updates/${slug}/`,
    identifier: data.markdownRemark.id,
    title: title,
  }

  const formattedOgImage = ogimage?.childImageSharp?.original?.src

  return (
    <Layout className="u-bg-black2">
      <SEO title={title} description={excerpt} image={formattedOgImage} />
      <div className="u-Pt-xl md:u-Pt-2xl u-pb-xl u-Mb-xl u-bg-black-3">
        <section className="o-container">
          <div style={{ margin: isWide ? '0 10rem' : '' }}>
            <span className="u-text-p10">
              in{' '}
              <Link to="/updates/" className="u-text-p10">
                Platform Updates
              </Link>
            </span>
            <h3 className="u-text-p3 u-mb-xl">{title}</h3>
            <h2 className="u-text-p6 u-font-normal u-color-gray-2">
              {author && author[0]} in {categories && categories[0]}, on{' '}
              {format(new Date(date), 'MMMM dd, yyyy')}
            </h2>
          </div>
        </section>
      </div>
      <section className="o-container u-Mb-xl">
        <article
          className="update-body"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </section>
      <section className="u-Px-sm md:u-Px-md u-Pb-0 u-Pt-md md:u-Py-md md:u-Mt-md u-bg-black u-w-full u-flex u-justify-center u-bg-black-3">
        <div
          style={{
            margin: isWide ? '0 10rem' : '',
            width: isWide ? '60%' : '100%',
          }}
        >
          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
      </section>
      {moreUpdates.length > 0 && (
        <section className="u-Py-xl u-bg-black">
          <More
            label={
              <Fragment>
                See other
                <span className="u-text-mark u-border-red"> Platform </span>
                Updates
              </Fragment>
            }
            links={moreUpdates.map(update => ({
              title: update.frontmatter.title,
              link: `/updates/${update.frontmatter.slug}`,
              linkText: 'Read more',
              description: `${update.excerpt.split('. ', 1)[0]}.`,
            }))}
            rightLink={{ label: 'See all updates', to: '/updates/all' }}
          />
        </section>
      )}
    </Layout>
  )
}

Update.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Update
